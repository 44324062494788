exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-adopt-a-shark-js": () => import("./../../../src/pages/contact-us/adopt-a-shark.js" /* webpackChunkName: "component---src-pages-contact-us-adopt-a-shark-js" */),
  "component---src-pages-contact-us-careers-at-collins-js": () => import("./../../../src/pages/contact-us/careers-at-collins.js" /* webpackChunkName: "component---src-pages-contact-us-careers-at-collins-js" */),
  "component---src-pages-contact-us-covid-19-update-js": () => import("./../../../src/pages/contact-us/covid-19-update.js" /* webpackChunkName: "component---src-pages-contact-us-covid-19-update-js" */),
  "component---src-pages-contact-us-map-and-directions-js": () => import("./../../../src/pages/contact-us/map-and-directions.js" /* webpackChunkName: "component---src-pages-contact-us-map-and-directions-js" */),
  "component---src-pages-contact-us-orthodontic-referral-js": () => import("./../../../src/pages/contact-us/orthodontic-referral.js" /* webpackChunkName: "component---src-pages-contact-us-orthodontic-referral-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mayo-insurance-mayo-allied-staff-residents-and-fellows-js": () => import("./../../../src/pages/mayo-insurance/mayo-allied-staff-residents-and-fellows.js" /* webpackChunkName: "component---src-pages-mayo-insurance-mayo-allied-staff-residents-and-fellows-js" */),
  "component---src-pages-mayo-insurance-mayo-physicians-consulting-and-voting-staff-js": () => import("./../../../src/pages/mayo-insurance/mayo-physicians-consulting-and-voting-staff.js" /* webpackChunkName: "component---src-pages-mayo-insurance-mayo-physicians-consulting-and-voting-staff-js" */),
  "component---src-pages-orthodontics-braces-js": () => import("./../../../src/pages/orthodontics/braces.js" /* webpackChunkName: "component---src-pages-orthodontics-braces-js" */),
  "component---src-pages-orthodontics-cost-of-braces-and-invisalign-js": () => import("./../../../src/pages/orthodontics/cost-of-braces-and-invisalign.js" /* webpackChunkName: "component---src-pages-orthodontics-cost-of-braces-and-invisalign-js" */),
  "component---src-pages-orthodontics-invisalign-js": () => import("./../../../src/pages/orthodontics/invisalign.js" /* webpackChunkName: "component---src-pages-orthodontics-invisalign-js" */),
  "component---src-pages-orthodontics-retainers-js": () => import("./../../../src/pages/orthodontics/retainers.js" /* webpackChunkName: "component---src-pages-orthodontics-retainers-js" */),
  "component---src-pages-orthodontics-when-should-you-see-an-orthodontist-js": () => import("./../../../src/pages/orthodontics/when-should-you-see-an-orthodontist.js" /* webpackChunkName: "component---src-pages-orthodontics-when-should-you-see-an-orthodontist-js" */),
  "component---src-pages-resources-books-js": () => import("./../../../src/pages/resources/books.js" /* webpackChunkName: "component---src-pages-resources-books-js" */),
  "component---src-pages-resources-braces-friendly-eats-js": () => import("./../../../src/pages/resources/braces-friendly-eats.js" /* webpackChunkName: "component---src-pages-resources-braces-friendly-eats-js" */),
  "component---src-pages-resources-collins-blog-index-js": () => import("./../../../src/pages/resources/collins-blog/index.js" /* webpackChunkName: "component---src-pages-resources-collins-blog-index-js" */),
  "component---src-pages-resources-eating-with-braces-js": () => import("./../../../src/pages/resources/eating-with-braces.js" /* webpackChunkName: "component---src-pages-resources-eating-with-braces-js" */),
  "component---src-pages-resources-free-reports-and-articles-js": () => import("./../../../src/pages/resources/free-reports-and-articles.js" /* webpackChunkName: "component---src-pages-resources-free-reports-and-articles-js" */),
  "component---src-pages-resources-free-top-five-things-you-need-to-know-about-invisalign-js": () => import("./../../../src/pages/resources/free/top-five-things-you-need-to-know-about-invisalign.js" /* webpackChunkName: "component---src-pages-resources-free-top-five-things-you-need-to-know-about-invisalign-js" */),
  "component---src-pages-resources-free-top-ten-things-you-must-know-before-choosing-your-familys-orthodontist-js": () => import("./../../../src/pages/resources/free/top-ten-things-you-must-know-before-choosing-your-familys-orthodontist.js" /* webpackChunkName: "component---src-pages-resources-free-top-ten-things-you-must-know-before-choosing-your-familys-orthodontist-js" */),
  "component---src-pages-resources-troubleshooting-your-braces-js": () => import("./../../../src/pages/resources/troubleshooting-your-braces.js" /* webpackChunkName: "component---src-pages-resources-troubleshooting-your-braces-js" */),
  "component---src-pages-the-donut-index-js": () => import("./../../../src/pages/the-donut/index.js" /* webpackChunkName: "component---src-pages-the-donut-index-js" */),
  "component---src-pages-the-next-step-common-questions-js": () => import("./../../../src/pages/the-next-step/common-questions.js" /* webpackChunkName: "component---src-pages-the-next-step-common-questions-js" */),
  "component---src-pages-the-next-step-new-patient-forms-js": () => import("./../../../src/pages/the-next-step/new-patient-forms.js" /* webpackChunkName: "component---src-pages-the-next-step-new-patient-forms-js" */),
  "component---src-pages-the-next-step-refer-a-friend-js": () => import("./../../../src/pages/the-next-step/refer-a-friend.js" /* webpackChunkName: "component---src-pages-the-next-step-refer-a-friend-js" */),
  "component---src-pages-the-next-step-schedule-a-free-consult-js": () => import("./../../../src/pages/the-next-step/schedule-a-free-consult.js" /* webpackChunkName: "component---src-pages-the-next-step-schedule-a-free-consult-js" */),
  "component---src-pages-why-collins-meet-dr-collins-js": () => import("./../../../src/pages/why-collins/meet-dr-collins.js" /* webpackChunkName: "component---src-pages-why-collins-meet-dr-collins-js" */),
  "component---src-pages-why-collins-our-team-js": () => import("./../../../src/pages/why-collins/our-team.js" /* webpackChunkName: "component---src-pages-why-collins-our-team-js" */),
  "component---src-pages-why-collins-patient-experiences-js": () => import("./../../../src/pages/why-collins/patient-experiences.js" /* webpackChunkName: "component---src-pages-why-collins-patient-experiences-js" */),
  "component---src-pages-why-collins-see-our-work-js": () => import("./../../../src/pages/why-collins/see-our-work.js" /* webpackChunkName: "component---src-pages-why-collins-see-our-work-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

